.OTPInput {
	display: flex;
	direction: ltr;

	input {
		border: none;
		border-color: transparent;
		border-bottom: 1px solid #000;
		margin: 0.9375em 0.3125em;
		padding: 0.3125em 0;
		outline: none;
		font-size: 1.125em;
		width: 2em;
		text-align: center;
		font-weight: bold;
		box-shadow: none;
	}
}
