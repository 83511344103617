.QRLogin {
	width: 100%;

	display: flex;
	align-items: center;

	font-size: 0.625em;
	margin-top: 1.8em;

	width: 100%;

	padding: 0 0.9375em;

	&-img {
		width: 2.2em;
		height: 2.2em;
	}

	&-txt {
		flex: 1;
		height: 100%;

		padding: 0 0.9375em;

		p {
			margin: 0;
			font-weight: bold;
			color: #000;
		}
	}

	&-btn {
		background-color: #8d00f5;
		color: #fff;
		border-radius: 1rem;
		padding: 0.5em 1em;
		cursor: pointer;
		font-size: 1.25em;

		&:hover {
			opacity: 0.9;
		}

		&:active {
			transform: scale(1.01);
		}
	}

	&-popup {
		visibility: visible;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: max-content;
		min-height: fit-content;
		border-radius: 0.9375em;
		box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.287);
		background-color: #fff;

		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 1.5625em 3.125em;

		z-index: 52;

		&-overlay {
			position: fixed;

			// fix for inner fixed position
			top: -100vh;
			left: -100vw;
			width: 500vw;
			height: 500vh;

			background-color: #00000061;

			z-index: 51;
		}

		&-logo {
			max-width: 40%;
		}

		&-title {
			margin: 0;
			margin-top: 0.9375em;
			color: #000;
			font-size: 1.5625em;
			font-weight: 700;
		}

		&-wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 1.25em;

			&-help {
				padding: 0 0.9375em;

				&-list {
					display: flex;
					flex-direction: column;
					margin: 0;
					height: 80%;
					margin-top: 1.5625em;
					padding: 0;

					li {
						font-size: 1.1em;
						margin-bottom: 1.875em;
						max-width: 93%;
					}
				}
			}

			&-qr {
				width: 203px;
				height: 203px;
				overflow: hidden;
				position: relative;

				&-logo {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					background-color: white;
					width: 25%;
					height: 25%;
					border-radius: 50%;

					display: flex;

					transition: 1s all;

					&-auth-loading {
						padding: 0.8em;
					}
				}

				&-loading {
					height: 100%;
					width: 100%;
					background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
					animation: placeholderShimmer 2s linear 0s infinite normal forwards;
				}

				@keyframes placeholderShimmer {
					0% {
						background-position: -500px 0;
					}

					100% {
						background-position: 500px 0;
					}
				}
			}
		}

		&-back-btn {
			margin: 1.25em 0 0 0;
			display: flex;
			align-items: center;

			direction: ltr;

			color: rgb(42, 42, 42);
			font-size: 0.75em;
			cursor: pointer;
			position: relative;

			&:hover {
				font-weight: bold;
			}

			&::before {
				content: '';
				display: block;
				background-image: url('https://cdnweb.anghami.com/web/assets/icons/all/arrow-back.svg');
				background-repeat: no-repeat;
				width: 0.9em;
				height: 0.9em;
				align-self: flex-end;
				position: absolute;
				left: -1.2em;
			}
		}
	}
}
