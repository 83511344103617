.default-header-wrap {
    position: relative;
    background-size: 100%;
    height: fit-content;
    background-repeat: no-repeat;
    min-height: 26em;
    justify-content: center;
    padding: 0 3em;
    background-size: cover;
    background-position: center;

    .main-header-title {
        font-size: 3em;
        font-weight: 600;
        color: #ffffff;
        max-width: 15em;
    }

    .default-header-inner-img {
        position: absolute;
        top: 5%;
        right: 5%;
        img {
            max-width: 20em;
        }
    }

    .main-header-subtitle {
        color: white;
        font-size: 1em;
        padding: 0.5em 0 2em 0;
        max-width: 45em;
    }

    .backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .main-header-title, .main-header-subtitle, .default-btn {
        z-index: 1;
    }
}

.default-btn {
    padding: 0.75em 1.5em !important;
    font-size: 1.1em !important;
    color: white;
}

.body-part-one {
    width: 60%;
    margin: 3em auto 2em auto;
    img {
        max-width: 7em;
        margin-top: 1em;
    }
    .body-title {
        font-size: 1.3em;
        font-weight: 600;
        color: black;
        max-width: 15em;
        text-align: center;
    }
    .reward-box {
        flex-direction: column;
    }
}

.body-part-two {
    margin: 2em auto 3em auto;
    .gift-title {
        font-size: 1.7em;
        color: black;
        font-weight: 600;
        text-align: center;
    }
    .gift-subtitle {
        padding: 0.5em 0;
        font-weight: 600;
        font-size: 1.2em;
    }
    .orange {
        color: #FFAF5C;
    }

    .purple {
        color: #5C5CFF;
    }
    img {
        max-width: 18em;
    }
    .border-box-wrap-orange, .border-box-wrap-purple {
        margin: 0 0.5em;
    }
    .box-wrap-title {
        font-size: 1.4em;
        padding: 0.5em 0;
    }
    .box-wrap-subtitle {
        color: white;
        padding: 0.5em 1em;
        font-size: 1em;
    }
    .default-btn {
        font-size: 1em;
    }
}

.body-wrapper {
    .benefits-title {
        font-size: 1.7em;
        padding-top: 1em;
    }
    .benefits {
        padding-top: 0;
        max-width: 90%;
        .benefit img {
            max-width: 4.5em;
        }
        .benefit p {
            color: black !important;
            font-size: 1.1em;
        }
    }
}

.extradata-wrapper {
    cursor: pointer;
    background-color: rgba(255,255,255,0.1);
    color: white;
    font-size: 1.3rem;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1em;
    @media (max-width: 768px) {
        font-size: 1.1rem;
    }
}

.anghami-studios-header {
    min-height: 25em;
    justify-content: flex-end;
    padding: 5em;
    padding-top: 0;
    @media (max-width: 768px) {
        height: 27em !important;
        min-height: unset !important;
        padding: 0 2em 2em!important;
    }
}

.anghami-studios-title {
    font-size: 5rem;
    color: white !important;
    @media (max-width: 768px) {
        font-size: 3.2rem;
    }
}

.anghami-studios-extra-asset {
    max-width: 15em;
    margin: 1.2em 0;
    @media (max-width: 768px) {
        max-width: 20em;
    }
}

.anghami-studios-description {
    display: none;
}

.main-header-title .highlighted {
    color: #FFAF5C;
}

@media (max-width: 768px) {
    .default-header-wrap {
        padding: 0 1em;
        min-height: 50em;
        .main-header-subtitle {
            line-height: 1.3em;
            font-size: 1.2em;
        }
    }
    .body-part-two {
        flex-direction: column;
        .carousel-wrapper {
            max-width: 90% !important;
        }
    }
    .body-part-one {
        flex-direction: column;
        margin: 2em auto;
        width: 95%;
        .body-title {
            max-width: unset !important;
            font-size: 1.2em;
            padding: 0 1em;
            text-align: left;
        }
    }
    .gift-title {
        width: 85%;
    }
    .gift-box-wrapper {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .border-box-wrap-orange, .border-box-wrap-purple {
        margin-bottom: 1em !important;
    }
    .body-part-one img {
        max-width: 5em;
        margin-top: 0;
    }
    .team-wrap {
        margin-bottom: 1.5em;
    }
    .body-part-two {
        margin-top: 1em !important;
        img {
            width: 16em;
        }
        .box-wrap-title {
            font-size: 1.2em;
        }
    }
    .default-header-inner-img {
        top: 2em !important;
        left: 0;
        right: 0;
        img {
            max-width: 15em !important;
            display: block;
            margin: auto;
        }
    }
    .reward-box {
        flex-direction: row-reverse !important;
        justify-content: flex-end !important;
        width: 90%;
    }
    .cards-carousel-img {
        max-width: 15em;
        max-height: 15em;
    }
    html[lang="ar"] .body-part-one .body-title {
        text-align: right !important;
    }
}
