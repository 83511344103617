.FacebookLogin {
	width: 100%;

	font-size: unset !important; // fix the fb_reset class
	font-family: inherit !important;

	&-btn {
		background: linear-gradient(90deg, #415da3, #0457c9);
	}
}
