.GoogleLogin {
	width: 100%;

	&-btn {
		background-color: #4285f4;

		&-loading {
			background-color: rgba(66, 133, 244, .69);
			cursor: not-allowed;
		}
	}
}
