.LocalLogin {
	width: 100%;

	&-btn {
		border: 1px solid #000;
		color: #000;
	}

	&-text-btn {
		margin: 0.625em 0 0 0;

		color: rgb(42, 42, 42);
		font-size: 0.75em;
		cursor: pointer;
		text-align: center;

		&:hover {
			font-weight: bold;
		}
	}

	&-inputs {
		width: 100%;

		&-form {
			&-input {
				outline: none;
				width: 100%;
				border: 1px solid #000;
				text-align: center;
				margin: 0.7em auto 0 auto;
				min-height: 3em;
				color: #000;
				padding: 0px 3px;
				border-radius: 2.0625em;
				font-size: 0.88em;
				direction: ltr;

				&.error {
					border-color: rgb(251, 66, 66);
				}

				&-error {
					border-color: rgb(251, 66, 66);
				}

				&-date {
					padding: 0 8px;
				}

				&-hidden {
					display: none;
				}
			}
		}

		&-error-message {
			color: rgb(251, 66, 66);
			margin: 0;
			padding: 0;
			font-size: 0.8125em;
			margin-top: 0.625em;
			text-align: center;
		}
	}

	&-popup {
		visibility: visible;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 390px;
		@media (max-width: 400px) {
			width: 90%;
		}

		min-height: fit-content;
		border-radius: 0.9375em;
		box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.287);
		background-color: #fff;

		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 1.5625em;

		z-index: 52;

		&-overlay {
			position: fixed;

			// fix for inner fixed position
			top: -100vh;
			left: -100vw;
			width: 500vw;
			height: 500vh;

			background-color: #00000061;

			z-index: 51;
		}

		&-logo {
			max-width: 70%;
		}

		&-heading {
			color: #8d00f2;
			text-align: center;
			margin: 0;
			margin-top: 0.7em;
			font-size: 1.4em;
		}

		&-title {
			margin: 0;
			margin-top: 0.9375em;
			color: #000;
			font-size: 0.875em;
			text-align: center;
		}

		&-btn {
			background-color: #8d00f5;
		}

		&-btn-empty {
			color: #000;
			border: 1px solid #000;
		}

		&-back-btn {
			margin: 1.25em 0 0 0;
			display: flex;
			align-items: center;

			direction: ltr;

			color: rgb(42, 42, 42);
			font-size: 0.75em;
			cursor: pointer;
			position: relative;

			&:hover {
				font-weight: bold;
			}

			&::before {
				content: '';
				display: block;
				background-image: url('https://cdnweb.anghami.com/web/assets/icons/all/arrow-back.svg');
				background-repeat: no-repeat;
				width: 0.9em;
				height: 0.9em;
				align-self: flex-end;
				position: absolute;
				left: -1.2em;
			}
		}

		&-avatar {
			display: none; // disabled for now
			width: 5em;
			height: 5em;
			border-radius: 50%;
			border: 1px solid rgb(42, 42, 42);
			overflow: hidden;
			margin: 0.7em auto 0 auto;
			text-align: center;

			img {
				height: 100%;
			}

			input {
				width: 0;
				height: 0;
				opacity: 0;
			}
		}

		&-label {
			display: inline-block;
			margin-top: 0.85em;
			font-size: 0.8em;
		}

		&-radio-wrapper {
			display: flex;
			align-items: center;
			margin-top: 0.4em;
		}
		&-radio {
			display: flex;
			align-items: center;
			margin: 0.8em 0.75em 0 0;
			cursor: pointer;

			input {
				width: 0;
				height: 0;
				opacity: 0;

				&:checked + span > span {
					background: linear-gradient(90deg, #8d00f2 0, #e1418c);
					width: 80%;
					height: 80%;
					border-radius: 50%;
				}
			}

			&-custom {
				display: inline-flex;
				width: 1em;
				height: 1em;
				border: 2px solid #8d00f2;
				border-radius: 50%;
				margin: 0 0.3em;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
