.PhoneLogin {
	width: 100%;

	&-btn {
		border: 1px solid #000;
		color: #000;
	}

	&-number {
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-radius: 2.0625em;
		position: relative;
		text-align: center;
		margin: 0.9375em auto 0 auto;
		min-height: 2.8125em;
		border: 1px solid #000;
		color: #000;
		font-size: 0.875em;
		font-weight: 600;
		padding: 0px 3px;

		direction: ltr;

		&.error {
			border-color: rgb(251, 66, 66);
		}

		&-country {
			position: relative;
			display: flex;
			padding-left: 0.625em;

			&-selector {
				opacity: 0;
				position: absolute;
				left: 0.625em;
				top: 0.625em;
			}

			&-code {
				display: flex;
				align-items: center;

				pointer-events: none;
				height: 100%;
				padding: 0.3125em 0;
				margin-left: 0.5em;

				&::before {
					content: '';
					position: relative;
					left: -0.3em;
					top: 0.2em;
					background-image: url('https://cdnweb.anghami.com/web/assets/icons/all/arrow-down.svg');
					background-repeat: no-repeat;
					width: 0.9em;
					height: 0.9em;
				}

				img {
					height: 1.5625em;
					width: 1.5625em;
					object-fit: contain;
				}

				p {
					margin: 0 0.3125em;
				}
			}
		}

		&-form {
			flex: 1;
			margin: 0.83em 0;

			&-input {
				outline: none;
				border: 0;
				width: 100%;
				height: 100%;

				-moz-appearance: textfield;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}

		&-error-message {
			color: rgb(251, 66, 66);
			margin: 0;
			padding: 0;
			font-size: 0.8125em;
			margin-top: 0.625em;
		}
	}

	&-popup {
		visibility: visible;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 390px;

		@media (max-width: 400px) {
			width: 90%;
		}

		min-height: fit-content;
		border-radius: 15px;
		box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.287);
		background-color: #fff;

		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 1.5625em;

		z-index: 52;

		&-overlay {
			position: fixed;

			// fix for inner fixed position
			top: -100vh;
			left: -100vw;
			width: 500vw;
			height: 500vh;

			background-color: #00000061;

			z-index: 51;
		}

		&-logo {
			max-width: 70%;
		}

		&-title {
			margin: 0;
			margin-top: 0.9375em;
			color: #000;
			font-size: 0.875em;
			text-align: center;
		}

		&-btn {
			background-color: #8d00f5;
		}

		&-back-btn {
			margin: 1.25em 0 0 0;
			display: flex;
			align-items: center;

			direction: ltr;

			color: rgb(42, 42, 42);
			font-size: 0.75em;
			cursor: pointer;
			position: relative;

			&:hover {
				font-weight: bold;
			}

			&::before {
				content: '';
				display: block;
				background-image: url('https://cdnweb.anghami.com/web/assets/icons/all/arrow-back.svg');
				background-repeat: no-repeat;
				width: 0.9em;
				height: 0.9em;
				align-self: flex-end;
				position: absolute;
				left: -1.2em;
			}
		}
	}

	&-otp {
		display: flex;
		flex-direction: column;
		align-items: center;
		direction: ltr;

		width: 100%;

		&-title {
			font-size: 1.25em;
			font-weight: bold;
			color: #8d00f5;
			margin: 0.9375em 0;
		}

		&-subtitle {
			margin: 0;
			font-size: 0.8125em;
		}

		&-input {
			border-bottom: 1px solid #000;
			margin: 0.9375em 0.3125em;
			padding: 0 0.9375em;

			direction: ltr;

			input {
				padding: 0.3125em 0;
				border: none;
				outline: none;
				font-size: 1.125em;
			}
		}

		&-btns {
			display: flex;
			justify-content: space-around;
			width: 100%;

			margin-top: 0.9375em;

			&-btn {
				border: 1px solid #000;
				border-radius: 0.9375em;
				padding: 0.625em 0.9375em;
				margin: 0;
				cursor: pointer;
				font-size: 1em;

				&-disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}
			}
		}
	}
}
