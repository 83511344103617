.LoginPopup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 390px;

	font-size: 17px;

	@media (max-width: 400px) {
		width: 90%;
		font-size: 14px;
	}

	min-height: fit-content;
	border-radius: 15px;
	box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.287);
	background-color: #fff;

	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 1.5625em;

	z-index: 52;

	&-hidden {
		visibility: hidden;
	}

	&-overlay {
		position: fixed;
		top: 0;
		left: 0;

		width: 100vw;
		height: 100vh;

		background-color: #00000061;

		z-index: 51;
	}

	&-logo {
		max-width: 13rem;
	}

	&-title {
		margin: 0;
		margin-top: 0.9375em;
		color: #000;
		font-size: 0.875em;
		text-align: center;
	}

	&-loginwithapp {
		width: 100%;
		text-align: center;

		display: none;

		@media (max-width: 780px) {
			display: block;
		}

		&-subtitle {
			margin: 0.625em 0 0 0;
			font-size: 0.8125em;
			font-weight: bold;
		}
	}

	&-otherlogin-link {
		margin: 0.625em 0 0 0;

		color: #000;
		font-size: 0.8125em;
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			font-weight: bold;
		}
	}

	&-back-btn {
		margin: 1.25em 0 0 0;
		display: flex;
		align-items: center;

		direction: ltr;

		color: rgb(42, 42, 42);
		font-size: 0.75em;
		cursor: pointer;
		position: relative;

		&:hover {
			font-weight: bold;
		}

		&::before {
			content: '';
			display: block;
			background-image: url('https://cdnweb.anghami.com/web/assets/icons/all/arrow-back.svg');
			background-repeat: no-repeat;
			width: 0.9em;
			height: 0.9em;
			align-self: flex-end;
			position: absolute;
			left: -1.2em;
		}
	}

	&-divider {
		border-top: 1px solid #d3d3d3;
		margin-top: 0.9375em;
		width: 100%;
	}

	&-recaptcha-notice {
		font-size: 0.75em;
		text-align: center;

		margin: 0.9375em 0 0 0;

		a,
		a:visited {
			color: #000;
		}
	}
}
