.GenericButton {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-radius: 2.0625em;
	position: relative;
	text-align: center;
	margin: 0.9375em auto 0 auto;
	min-height: 3em;
	border: none;
	cursor: pointer;
	color: #fff;
	font-size: 0.875em;
	font-weight: 600;

	transition: 300ms opacity;

	&:hover {
		opacity: .9;
	}

	&:active {
		transform: scale(1.01);
	}

	&-icon {
		position: absolute;
		left: 1.375em;
		top: 0.8125em;
		width: 1.25em;
		height: 1.25em;

		padding: 1px;
		box-sizing: content-box;

		img {
			width: 100%;
			height: 100%;
		}
	}
}
